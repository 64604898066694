import React from "react";
import { Link } from "gatsby";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Image } from "../components/Image";

import EmptyImage from "../images/undraw_blank_canvas_3rbb.svg";

const NotFoundPage = () => (
  <>
    <Header />
    <div className="mt-24 max-w-xl mx-auto flex flex-col justify-center items-center px-8 py-10 sm:px-12 sm:py-8 lg:px-16">
      <h1 className="text-4xl leading-9 tracking-tight sm:text-5xl sm:leading-10 whitespace-nowrap">
        Page not found.
      </h1>
      <div className="mt-8 sm:flex">
        <div className="rounded-md shadow">
          <Link
            to="projects"
            className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-purple-900 hover:bg-purple-900 focus:outline-none focus:ring transition duration-150 ease-in-out"
          >
            Discover our projects
          </Link>
        </div>
        <div className="mt-3 sm:mt-0 sm:ml-3">
          <Link
            to="about-us"
            className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-purple-900 bg-purple-100 hover:text-purple-900 hover:bg-purple-50 focus:outline-none focus:ring focus:border-purple-100 transition duration-150 ease-in-out"
          >
            Learn More
          </Link>
        </div>
      </div>
      <Image className="mt-12  w-full" src={EmptyImage} alt="404 image" />
    </div>
    <Footer />
  </>
);

export default NotFoundPage;
